<script>
import EntranceGroup from '@/components/EntranceGroup'
import { getAction } from '@/command/netTool'
export default {
  mounted() {
    this.init()
    this.getList()
  },
  data() {
    return {
      current: 1,
      pages: 1,
      list: [],
      limit: 8, //每页显示条数
    }
  },
  methods: {
    getList() {
      getAction('/api/admin/app/appList').then((e) => {
        this.list = [].concat(e)
        this.pages = Math.ceil(this.list.length / this.limit)
      })
    },
    init() {
      // this.$refs.video.play()
      // setTimeout(() => {
      //   VanillaTilt.init(document.querySelectorAll('.entrance-main'), {
      //     max: 25,
      //     speed: 400,
      //     easing: 'cubic-bezier(.03,.98,.52,.99)',
      //     perspective: 500,
      //     transition: true,
      //   })
      // }, 500)
    },
    renderBkVideo() {
      return (
        <img src={require('./asset/bg.png')} class="index-img" />
        // <video
        //   src={require('./asset/慈溪登录背景视频.mp4')}
        //   class="index-video"
        //   muted={'muted'}
        //   control={false}
        //   autoPlay={true}
        //   ref="video"
        // />
      )
    },
    renderHeader() {
      return (
        <div class="index-header">
          <div />
        </div>
      )
    },
    renderPageData() {
      let start = (this.current - 1) * this.limit
      if (start >= this.list.length) start = 0
      let end = this.current * this.limit
      if (end >= this.list.length) end = this.list.length
      return this.list.slice(start, end)
    },
    renderIconGroup() {
      return (
        <EntranceGroup
          data={this.renderPageData().map((e) => ({ name: e.appName, icon: e.appIcon, img: e.appImg, href: e.appUrl }))}
        />
      )
    },
    renderContentFoot() {
      const data = [
        {
          img: require('./asset/normal.png'),
          display: this.current === 1,
          roate: 0,
          onClick: () => {
            if (this.current === 1) return
            this.current--
          },
        },
        {
          img: require('./asset/focus.png'),
          display: this.current !== 1,
          roate: 180,
          onClick: () => {
            if (this.current === 1) return
            this.current--
          },
        },
        {
          img: require('./asset/normal.png'),
          display: this.current === this.pages,
          roate: 180,
          onClick: () => {
            if (this.current === this.pages) return
            this.current++
          },
        },
        {
          img: require('./asset/focus.png'),
          display: this.current !== this.pages,
          roate: 0,
          onClick: () => {
            if (this.current === this.pages) return
            this.current++
          },
        },
      ].filter((e) => e.display)
      return (
        <div class={'content-foot'}>
          {data.map((e) => {
            return (
              <img
                src={e.img}
                style={{ width: '56px', height: '51px', transform: `rotate(${e.roate || 0}deg)` }}
                onClick={() => e.onClick && e.onClick()}
              />
            )
          })}
        </div>
      )
    },
    renderContent() {
      return (
        <div class="index-content">
          {this.renderIconGroup()}
          {this.renderContentFoot()}
        </div>
      )
    },
    renderBody() {
      return (
        <div class="index-body">
          {this.renderHeader()}
          {this.renderContent()}
        </div>
      )
    },
  },
  render() {
    return (
      <div class="index-main">
        {this.renderBkVideo()}
        {this.renderBody()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.index-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
.index-video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.index-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.index-body {
  position: absolute;
  width: calc(100vw);
  // width: 1920px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
}
.index-header {
  min-height: 10vw;
  width: 100%;
  background-image: url('./asset/header.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  // & > div {
  //   width: 335px;
  //   height: 46px;
  //   background-image: url('./asset/title.png');
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  //   margin-top: 20px;
  // }
}
.index-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-foot {
  width: calc(220px * 6);
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    cursor: pointer;
    -webkit-user-drag: none;
  }
  & > img:last-child {
    margin-left: 85px;
  }
  position: fixed;
  bottom: 6%;
}
</style>
