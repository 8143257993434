<script>
import { message } from 'ant-design-vue'
import { ACCESS_TOKEN } from "../../store/mutation-types"

export default {
  data() {
    return {
      select: 0,
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onJump() {
      if (this.item.href) {
        window.location.href = this.item.href + '?token=' + localStorage.getItem(ACCESS_TOKEN)
      }else {
        message.error('开发中，敬请期待')
      }
    },
    mouseover() {
      this.select = 1
    },
    mouseout() {
      this.select = 0
    },
  },
  //  <img src={this.item.icon} />
  // style={{backgroundImage: `url(${this.item.icon})`}}>
  render() {
    return (
      <div class="entrance-main" id="box" onClick={this.onJump} onMouseover={this.mouseover} onMouseout={this.mouseout}>
        <div class="entrance-bk">
          { this.select == 0 ? <img src={this.item.icon} /> : <img src={this.item.img} /> }
        </div>
        <div>{this.item.name}</div>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
 @keyframes scaleDraw {  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
.entrance-main {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5vh;
  & > div:last-child {
    font-size: 30px;
    font-family: YouSheBiaoTiHei;
    color: #c4f0ff;
  }
  &:hover {
    animation: scaleDraw 0.8s ease;
    animation-fill-mode: forwards;
    .entrance-bk {
      // background-image: url('./asset/1.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    & > div:last-child {
      font-size: 30px;
      font-family: YouSheBiaoTiHei;
      color: #E7C108;
    }
  }
}
.entrance-bk {
  width: 100%;
  // height: 185px;
  // min-width: 200px;
  // min-height: 185px;
  // width: 200px;
  // height: 185px;
  // min-width: 200px;
  // min-height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url('./asset/01.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  img {
    width: 50%;
    min-width: 50%;
    // height: 185px;
    -webkit-user-drag: none;
  }
}
</style>
